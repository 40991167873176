import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { nanoid } from 'nanoid';
import { HubServiceOrder, HubServiceOrderItem, HubServiceOrderItemCompositionMember } from '../models/hub.model';
import { Order, OrderItem, OrderSubItem } from '../models/order';
import { OrderCheckStorage } from '../models/order-check.model';
import { copyObject, isDecimal, toFloat } from '../utils/functions';
import { EventService } from './event.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
   providedIn: 'root',
})
export class OrderService {
   private order: Order;

   constructor(private eventService: EventService, private localStorageService: LocalStorageService) {}

   getOrder(companyId: number): Order {
      const sessionStorageOrder = this.localStorageService.getMenuServiceOrder();

      if (!sessionStorageOrder || sessionStorageOrder.CompanyId !== companyId) {
         this.order = new Order(companyId, nanoid());
         this.saveOrderOnLocalStorage();
      } else {
         this.order = sessionStorageOrder;
      }

      return this.order;
   }

   private saveOrderOnLocalStorage() {
      this.localStorageService.setMenuServiceOrder(this.order);
   }

   cleanOrder(companyId: number) {
      this.order = new Order(companyId, nanoid());
      this.saveOrderOnLocalStorage();
      this.eventService.publishOrderChange(this.order);
   }

   addProduct(item: OrderItem) {
      this.order.Items.push(item);
      this.calculateOrderTotalValue(this.order);
      this.saveOrderOnLocalStorage();
      this.eventService.publishOrderChange(this.order);
   }

   updateProduct(item: OrderItem, itemIndexToEdit: number) {
      this.order.Items[itemIndexToEdit] = copyObject(item);
      this.calculateOrderTotalValue(this.order);
      this.saveOrderOnLocalStorage();
      this.eventService.publishOrderChange(this.order);
   }

   removeProduct(orderItem: OrderItem) {
      this.order.Items = this.order.Items.filter((item) => item !== orderItem);
      this.calculateOrderTotalValue(this.order);
      this.saveOrderOnLocalStorage();
      this.eventService.publishOrderChange(this.order);
   }

   calculateOrderTotalValue(order: Order) {
      const totalItemsValue = order.Items.reduce((total, currentItem) => {
         return (total += toFloat(currentItem.TotalValue));
      }, 0);

      const orderItemsQuantity = order.Items?.reduce((total, currentValue) => {
         return (total += isDecimal(currentValue.Quantity) ? 1 : currentValue.Quantity);
      }, 0);

      order.SubtotalValue = totalItemsValue;
      order.TotalValue = totalItemsValue;
      order.ItemsQuantity = orderItemsQuantity;
   }

   getHubServiceOrder(orderCheck: OrderCheckStorage, customerName: string): HubServiceOrder {
      const menuServiceOrder: HubServiceOrder = {
         CompanyId: this.order.CompanyId,
         OrderControlId: this.order.OrderControlId,
         TableNumber: orderCheck.IsGroupCheck ? orderCheck.OrderCheckCode : orderCheck.BindedTableNumber,
         IsGroupCheck: orderCheck.IsGroupCheck,
         OrderCheckCode: orderCheck.OrderCheckCode,
         CardId: null,
         CustomerName: customerName,
         Items: [],
      };

      if (this.order.Items && this.order.Items.length > 0) {
         this.order.Items.forEach((item) => {
            menuServiceOrder.Items.push({
               SellerCode: orderCheck.OperatorId,
               SellerLogin: orderCheck.OperatorLogin,
               ProductId: item.ProductId,
               ProductName: item.OriginalProductName,
               ProductTypeName: item.HasComplements ? 'complement' : item.ProductTypeName,
               ProductBarcode: item.ProductBarcode,
               Observation: item.CompleteObservation,
               IsFractioned: item.CanBeFractioned && !item.HasComplements,
               ProductIsCompositionMember: item.ProductIsCompositionMember,
               AdditionValue: 0,
               DiscountValue: 0,
               Quantity: item.Quantity,
               TotalValue: !item.HasComplements ? item.TotalValue : item.Quantity * item.SellValue,
               SellValue: item.SellValue,
               StepIndex: item.StepIndex,
               MarketingClassificationId: item.MarketingClassificationId,
               MarketingClassificationName: item.OriginalMarketingClassificationName,
               Printers: item.Printers,
               ApplyServiceCharge: item.ApplyServiceCharge,
               IsSplitOnSale: item.IsSplitOnSale,
               SubItems: this.getMenuServiceOrderSubItems(item),
               ProductCompositionMembers: this.getMenuServiceOrderCompositionMembers(item),
               PackageMembers: this.getMenuServiceOrderPackageMembers(item, orderCheck),
            });
         });
      }

      return menuServiceOrder;
   }

   getMenuServiceOrderCompositionMembers(item: OrderItem): HubServiceOrderItemCompositionMember[] {
      const compositionMembers: HubServiceOrderItemCompositionMember[] = [];

      if (item.CompositionMembers && item.ProductTypeName.toLowerCase() === 'rodízio') {
         item.CompositionMembers.forEach((comp) =>
            compositionMembers.push({
               ProductId: comp.Id,
               ProductBarcode: comp.MainBarcode,
               MaxQuantity: null,
            })
         );
      }

      return compositionMembers;
   }

   getMenuServiceOrderPackageMembers(item: OrderItem, orderCheck): HubServiceOrderItem[] {
      const pachageMembers: HubServiceOrderItem[] = [];

      if (item.PackageMembers) {
         item.PackageMembers.forEach((comp) => {
            const quantity = toFloat(comp.PackageMemberQuantity * item.Quantity, 4);
            pachageMembers.push({
               SellerCode: orderCheck.OperatorId,
               SellerLogin: orderCheck.OperatorLogin,
               ProductId: comp.Id,
               ProductName: comp.Name,
               ProductTypeName: comp.ProductTypeName,
               ProductBarcode: comp.MainBarcode,
               Observation: item.Observation,
               IsFractioned: comp.ProductUnitCanBeFractioned,
               ProductIsCompositionMember: comp.ProductIsCompositionMember,
               AdditionValue: 0,
               DiscountValue: 0,
               Quantity: quantity,
               SellValue: comp.SellValue,
               TotalValue: toFloat(quantity * comp.SellValue),
               StepIndex: null,
               MarketingClassificationId: comp.MarketingClassificationId,
               MarketingClassificationName: comp.OriginalMarketingClassificationName,
               Printers: comp.Printers,
               ApplyServiceCharge: comp.ApplyServiceCharge,
               IsSplitOnSale: comp.IsSplitOnSale,
               ProductCompositionMembers: null,
               PackageMembers: null,
            });
         });
      }

      return pachageMembers;
   }

   getMenuServiceOrderSubItems(item: OrderItem): HubServiceOrderItem[] {
      let menuServiceOrderSubItems: HubServiceOrderItem[] = [];

      if (item.CanBeFractioned && !item.HasComplements) {
         menuServiceOrderSubItems = item.SubItems.reduce((subItemObject: HubServiceOrderItem[], currentSubItem: OrderSubItem) => {
            subItemObject.push({
               ProductId: currentSubItem.ProductId,
               ProductName: currentSubItem.OriginalProductName,
               ProductBarcode: currentSubItem.ProductBarcode,
               Quantity: toFloat(currentSubItem.Quantity / currentSubItem.QuantityToShow),
               SellValue: toFloat(currentSubItem.TotalValue / currentSubItem.Quantity),
               TotalValue: currentSubItem.TotalValue,
               IsFractioned: currentSubItem.CanBeFractioned,
               AdditionValue: 0,
               DiscountValue: 0,
               MarketingClassificationId: currentSubItem.MarketingClassificationId,
               MarketingClassificationName: currentSubItem.OriginalMarketingClassificationName,
               ProductIsCompositionMember: false,
               Observation: '',
               ProductTypeName: currentSubItem.ProductTypeName,
               StepIndex: currentSubItem.StepIndex,
               ApplyServiceCharge: currentSubItem.ApplyServiceCharge,
               IsSplitOnSale: currentSubItem.IsSplitOnSale,
               Printers: currentSubItem.Printers,
               ProductCompositionMembers: [],
               PackageMembers: [],
            });

            return subItemObject;
         }, []);
      } else {
         const menuServiceOrderSubItem = item.SubItems.map((subItem) => {
            return {
               ProductId: subItem.ProductId,
               ProductName: subItem.OriginalProductName,
               ProductBarcode: subItem.ProductBarcode,
               Quantity: subItem.Quantity,
               SellValue: toFloat(subItem.TotalValue / subItem.Quantity),
               TotalValue: subItem.TotalValue,
               IsFractioned: subItem.CanBeFractioned,
               AdditionValue: 0,
               DiscountValue: 0,
               MarketingClassificationId: subItem.MarketingClassificationId,
               MarketingClassificationName: subItem.OriginalMarketingClassificationName,
               ProductIsCompositionMember: false,
               Observation: '',
               ProductTypeName: subItem.ProductTypeName,
               StepIndex: subItem.StepIndex,
               ApplyServiceCharge: subItem.ApplyServiceCharge,
               IsSplitOnSale: subItem.IsSplitOnSale,
               Printers: subItem.Printers,
               ProductCompositionMembers: [],
               PackageMembers: [],
            };
         });

         menuServiceOrderSubItems.push(...menuServiceOrderSubItem);
      }

      if (item.HasComplements) {
         const sub = this.convertOrderItemToOrderItem(item);
         menuServiceOrderSubItems.unshift(sub);
      }

      return menuServiceOrderSubItems;
   }

   convertOrderItemToOrderItem(item: OrderItem): HubServiceOrderItem {
      return {
         ProductId: item.ProductId,
         ProductBarcode: item.ProductBarcode,
         ProductName: item.OriginalProductName,
         ProductTypeName: item.ProductTypeName,
         MarketingClassificationId: item.MarketingClassificationId,
         MarketingClassificationName: item.OriginalMarketingClassificationName,
         Quantity: 1,
         SellValue: item.SellValue,
         TotalValue: item.SellValue,
         Observation: '',
         Printers: item.Printers,
         AdditionValue: 0,
         DiscountValue: 0,
         IsFractioned: false,
         ProductIsCompositionMember: false,
         ApplyServiceCharge: item.ApplyServiceCharge,
         IsSplitOnSale: item.IsSplitOnSale,
         ProductCompositionMembers: [],
         PackageMembers: [],
      };
   }

   hasUnsentOrder(orderDate: Date): boolean {
      const limitDate = moment().subtract(1, 'hour').format('DD/MM/YYYY HH:mm');
      const date = moment(orderDate).format('DD/MM/YYYY HH:mm');
      return limitDate > date;
   }
}
