import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MenuServiceProduct, MenuServiceProductStep, MenuServiceProductStepItem } from '../../models/menu-service.model';
import { OrderItem } from '../../models/order';
import { MenuService } from '../../services/menu-service.service';
import { OrderService } from '../../services/order.service';
import { copyObject, normalizeAccentedChars, toFloat } from '../../utils/functions';
import { ProductQuantityComponent } from '../product-quantity/product-quantity.component';

@Component({
   selector: 'modal-product-detail',
   templateUrl: './product-detail.component.html',
   styleUrls: ['./product-detail.component.scss'],
   animations: [
      trigger('overlay', [
         transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 0.5 }))]),
         transition(':leave', [animate('500ms', style({ opacity: 0 }))]),
      ]),
      trigger('modal', [
         transition(':enter', [style({ top: -999 }), animate('500ms', style({ top: '50%' }))]),
         transition(':leave', [animate('500ms', style({ top: -999 }))]),
      ]),
   ],
})
export class ProductDetailComponent implements OnInit {
   public openModal = false;
   public isEdit = false;
   public isViewMode = false;
   public stepItemsRequiredValuesComplete = true;
   public itemIndexToEdit = -1;
   public urlToBack: string;
   public orderItem: OrderItem;

   @ViewChild(ProductQuantityComponent, { static: true }) productQuantityComponent: ProductQuantityComponent;

   @HostListener('window:popstate', ['$event'])
   onPopState(event: Event) {
      this.close();
   }

   constructor(private orderService: OrderService, private menuService: MenuService) {}

   ngOnInit(): void {}

   close() {
      history.pushState(null, 'Pedemais Garçom', this.urlToBack);
      this.menuService.overflowService(true);

      this.openModal = false;
   }

   open(product: MenuServiceProduct, url: string, isViewMode: boolean = false) {
      this.isViewMode = isViewMode;
      this.isEdit = false;
      this.createOrderItem(product);
      this.checkIfStepItemsRequiredValuesComplete();
      this.openModal = true;

      this.urlToBack = url;
      history.pushState(null, 'Pedemais Garçom | Detalhe do produto', this.urlToBack + '/detalhes');

      this.menuService.overflowService(false);

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
   }

   openEdition(orderItem: OrderItem, index: number) {
      this.itemIndexToEdit = index;
      this.isEdit = true;
      this.orderItem = copyObject(orderItem);
      this.orderItem.SubItems = [];
      this.openModal = true;
   }

   addItemToCart(product: MenuServiceProduct) {
      this.isEdit = false;
      this.itemIndexToEdit = -1;
      this.createOrderItem(product, false);
      this.calculateTotalValue();
      this.saveProduct();
   }

   createOrderItem(product: MenuServiceProduct, useDefaultValues: boolean = true) {
      const canBeFractioned = product.ProductUnitCanBeFractioned && (!product.ProductSteps || product.ProductSteps.length === 0);
      this.orderItem = {
         ProductId: product.Id,
         MarketingClassificationId: product.MarketingClassificationId,
         MarketingClassificationName: product.MarketingClassificationName,
         OriginalMarketingClassificationName: product.OriginalMarketingClassificationName,
         ProductName: product.Name,
         OriginalProductName: product.OriginalName,
         ProductTypeName: product.ProductTypeName,
         AdditionalInfo: product.AdditionalInfo,
         ImageUrl: product.ImageUrl,
         SellValue: product.SellValue,
         OriginalSellValue: product.SellValue,
         GrossValue: product.SellValue,
         TotalValue: product.SellValue,
         CanBeFractioned: canBeFractioned,
         GetWeight: product.GetWeight,
         Observation: useDefaultValues ? '' : product.Observation,
         ProductBarcode: product.MainBarcode,
         ProductUnitAcronym: product.ProductUnitAcronym,
         Quantity: useDefaultValues ? 1 : product.Quantity,
         QuantityToShow: useDefaultValues ? 1 : product.Quantity,
         MaxQuantityOnSale: product.MaxQuantityOnSale,
         ProductStepMinValue: product.ProductStepMinValue,
         SubItems: [],
         ProductSteps: product.ProductSteps,
         ComboRequiredValuesComplete: false,
         IsProductsAgeRestricted: product.IsProductsAgeRestricted,
         ProductIsCompositionMember: product.ProductIsCompositionMember || false,
         ApplyServiceCharge: product.ApplyServiceCharge,
         IsSplitOnSale: product.IsSplitOnSale,
         Printers: product.Printers,
         ProductNotes: copyObject(product.ProductNotes),
         CompleteObservation: '',
         CompositionMembers: product.CompositionMembers,
         PackageMembers: product.PackageMembers,
         ServesHowManyPeople: product.ServesHowManyPeople,
         PackageMemberQuantity: product.PackageMemberQuantity,
      };

      this.orderItem.ProductSteps?.forEach((step) => {
         step.Quantity = 0;
         step.StepItems.forEach((item) => {
            item.Quantity = 0;
            item.QuantityToShow = 0;
         });
      });

      if (product.ProductUnitCanBeFractioned) {
         this.orderItem.StepIndex = 0;
      }
   }

   decrementProduct() {
      if (this.orderItem.Quantity === 1 && this.orderItem.CanBeFractioned) {
         this.orderItem.Quantity = this.orderItem.Quantity / 2;
         this.orderItem.TotalValue = toFloat(this.orderItem.SellValue / 2);
      } else if (this.orderItem.Quantity === 0.5) {
         this.orderItem.TotalValue = toFloat(this.orderItem.SellValue / 2);
         return;
      } else if (this.orderItem.Quantity === 1) {
         return;
      } else {
         this.orderItem.Quantity--;

         this.calculateTotalValue();
      }
   }

   incrementProduct() {
      if (this.orderItem.Quantity === 0.5 && this.orderItem.CanBeFractioned) {
         this.orderItem.Quantity = 1;
      } else {
         this.orderItem.Quantity++;
      }
      this.calculateTotalValue();
   }

   private calculateTotalValue() {
      if (this.orderItem.ProductSteps && this.orderItem.ProductSteps.length > 0) {
         this.calculateStepTotalValue();
      } else {
         this.orderItem.TotalValue = toFloat(this.orderItem.SellValue * this.orderItem.Quantity);
         this.orderItem.GrossValue = this.orderItem.TotalValue;
      }
   }

   decrementStepProduct(step: MenuServiceProductStep, item: MenuServiceProductStepItem) {
      item.QuantityToShow = item.QuantityToShow || 0;
      if (item.QuantityToShow > 0) {
         item.QuantityToShow -= 1;
         step.Quantity -= 1;
         this.calculateStepTotalValue();

         this.scrollToNextStep(step);
      }
   }

   incrementStepProduct(step: MenuServiceProductStep, item: MenuServiceProductStepItem) {
      item.QuantityToShow = item.QuantityToShow || 0;
      if (item.QuantityToShow <= step.MaxQuantity) {
         item.QuantityToShow += 1;
         step.Quantity += 1;
         this.calculateStepTotalValue();

         this.scrollToNextStep(step);
      }
   }

   checkStepProduct(event: Event, step: MenuServiceProductStep, item: MenuServiceProductStepItem) {
      event.preventDefault();

      step.StepItems.forEach((n) => {
         n.QuantityToShow = 0;
         n.Quantity = 0;
      });

      const unchecked = step.Quantity === 0 || step.MinQuantity > 0;
      step.Quantity = unchecked ? 1 : 0;
      item.QuantityToShow = step.Quantity;
      item.Quantity = step.Quantity;

      this.calculateStepTotalValue();

      this.scrollToNextStep(step);
   }

   private calculateStepTotalValue() {
      if (this.orderItem.ProductSteps && this.orderItem.ProductSteps.length > 0) {
         const comboStepSumTotalValue = this.orderItem.ProductSteps.reduce((stepSum, step: MenuServiceProductStep) => {
            if (step.IsFractionItems) {
               const comboStepQuantity = this.getStepSelectedItemsCount(step);
               switch (step.ValueCalculationType) {
                  case 1:
                     // Biggest sellPrice
                     const productWithBiggestSellPrice = this.getProductWithBiggestSellPrice(step);

                     stepSum += step.StepItems.reduce((stepItemSum: number, item: MenuServiceProductStepItem) => {
                        const itemQuantity = item.QuantityToShow && item.QuantityToShow > 0 ? (1.0 / comboStepQuantity) * 1 : 0;
                        item.StepIndex = step.Position;
                        item.CanBeFractioned = true;
                        // item.SellPrice = productWithBiggestSellPrice;
                        item.Quantity = toFloat(itemQuantity);
                        item.TotalValue = toFloat(productWithBiggestSellPrice * itemQuantity);
                        item.GrossValue = item.TotalValue;
                        return item.TotalValue ? productWithBiggestSellPrice : stepItemSum;
                     }, 0);

                     return stepSum;

                  case 2:
                     // Lowest sellPrice
                     const productWithLowestSellPrice = this.getProductWithLowestSellPrice(step);

                     stepSum += step.StepItems.reduce((stepItemSum: number, item: MenuServiceProductStepItem) => {
                        const itemQuantity = item.QuantityToShow && item.QuantityToShow > 0 ? (1.0 / comboStepQuantity) * 1 : 0;
                        item.StepIndex = step.Position;
                        item.CanBeFractioned = true;
                        item.SellPrice = productWithLowestSellPrice;
                        item.Quantity = toFloat(itemQuantity);
                        item.TotalValue = toFloat(productWithLowestSellPrice * itemQuantity);
                        item.GrossValue = toFloat(productWithLowestSellPrice * itemQuantity);
                        return item.TotalValue ? productWithLowestSellPrice : stepItemSum;
                     }, 0);

                     return stepSum;

                  case 3:
                     stepSum += step.StepItems.reduce((stepItemSum: number, item: MenuServiceProductStepItem) => {
                        const itemQuantity = item.QuantityToShow && item.QuantityToShow > 0 ? item.QuantityToShow / comboStepQuantity : 0;
                        item.StepIndex = step.Position;
                        item.CanBeFractioned = true;
                        item.Quantity = toFloat(itemQuantity);
                        item.TotalValue = toFloat(item.SellPrice * itemQuantity);
                        item.GrossValue = item.TotalValue;
                        return item.TotalValue ? stepItemSum + item.TotalValue : stepItemSum;
                     }, 0);

                     return stepSum;
               }
            } else {
               return (stepSum += step.StepItems.reduce((stepItemSum: number, item: MenuServiceProductStepItem) => {
                  item.Quantity = item.QuantityToShow;
                  item.TotalValue = item.QuantityToShow && item.QuantityToShow > 0 ? item.SellPrice * item.QuantityToShow : 0;
                  item.GrossValue = item.QuantityToShow && item.QuantityToShow > 0 ? item.SellPrice * item.QuantityToShow : 0;
                  return item.TotalValue ? stepItemSum + item.TotalValue : stepItemSum;
               }, 0));
            }
         }, 0);

         if (this.orderItem.SellValue === 0) {
            this.orderItem.TotalValue = toFloat(this.orderItem.Quantity * comboStepSumTotalValue);
            // this.orderItem.SellValue = toFloat(this.orderItem.TotalValue / this.orderItem.Quantity);
            this.orderItem.GrossValue = this.orderItem.TotalValue;
         } else {
            const hasComplements = this.orderItem.ProductSteps && this.orderItem.ProductSteps.length > 0;
            const subItensTotal = comboStepSumTotalValue * (hasComplements ? this.orderItem.Quantity : 1);
            const orderValue = this.orderItem.SellValue * this.orderItem.Quantity;
            this.orderItem.TotalValue = toFloat(orderValue + subItensTotal);
         }

         this.checkIfStepItemsRequiredValuesComplete();
      }
   }

   private checkIfStepItemsRequiredValuesComplete() {
      if (this.orderItem.ProductSteps && this.orderItem.ProductSteps.length > 0) {
         this.stepItemsRequiredValuesComplete = this.orderItem.ProductSteps.every((n) => n.Quantity >= n.MinQuantity);
      } else {
         this.stepItemsRequiredValuesComplete = true;
      }
   }

   private getStepSelectedItemsCount(step: MenuServiceProductStep) {
      return step.StepItems.reduce((sum, item) => {
         return item.QuantityToShow ? item.QuantityToShow + sum : sum;
      }, 0);
   }

   private getProductWithBiggestSellPrice(step: MenuServiceProductStep) {
      const productWithGreaterSellPriceFilter = step.StepItems.filter((n) => n.QuantityToShow > 0)
         .sort((item1, item2) => item1.SellPrice - item2.SellPrice)
         .reverse();
      let productWithGreaterSellPrice = null;
      if (productWithGreaterSellPriceFilter && productWithGreaterSellPriceFilter.length > 0) {
         productWithGreaterSellPrice = productWithGreaterSellPriceFilter[0].SellPrice;
      }

      return productWithGreaterSellPrice;
   }

   private getProductWithLowestSellPrice(step: MenuServiceProductStep) {
      const productWithGreaterSellPriceFilter = step.StepItems.filter((n) => n.QuantityToShow > 0).sort(
         (item1, item2) => item1.SellPrice - item2.SellPrice
      );
      let productWithGreaterSellPrice = null;
      if (productWithGreaterSellPriceFilter && productWithGreaterSellPriceFilter.length > 0) {
         productWithGreaterSellPrice = productWithGreaterSellPriceFilter[0].SellPrice;
      }

      return productWithGreaterSellPrice;
   }

   scrollToNextStep(step: MenuServiceProductStep) {
      if (step.Quantity === step.MaxQuantity) {
         setTimeout(() => {
            const index = step.Position + 1;
            const stepIndex = document.getElementById(`step-index-${index}`);
            if (stepIndex) {
               stepIndex.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'nearest',
               });
            }
         }, 100);
      }
   }

   getCompletedObservation() {
      this.orderItem.CompleteObservation = this.orderItem.Observation || '';

      if (this.orderItem.ProductNotes) {
         this.orderItem.ProductNotes.forEach((note) => {
            if (note.SelectedOption && note.SelectedOption.length > 0) {
               const noteDescription = `${note.SelectedOption} ${note.Name}`;
               this.orderItem.CompleteObservation += this.orderItem.CompleteObservation === '' ? noteDescription : `; ${noteDescription}`;
            }
         });
      }
      this.orderItem.CompleteObservation = normalizeAccentedChars(this.orderItem.CompleteObservation);
   }

   setProductQuantity(orderItem) {
      this.orderItem = orderItem;
      this.saveProduct();
   }

   saveProduct() {
      this.orderItem.Observation = normalizeAccentedChars(this.orderItem.Observation);
      this.getCompletedObservation();
      this.createOrderSubItems();

      this.updateProductOrder();

      this.close();
   }

   private updateProductOrder() {
      if (this.isEdit) {
         this.orderService.updateProduct(this.orderItem, this.itemIndexToEdit);
      } else {
         this.orderService.addProduct(this.orderItem);
      }
   }

   private createOrderSubItems() {
      if (this.orderItem.ProductSteps && this.orderItem.ProductSteps.length > 0) {
         this.orderItem.SubItems = [];
         this.orderItem.HasComplements = this.orderItem.ProductTypeName?.toLowerCase() !== 'combo';

         this.orderItem.ProductSteps.forEach((step: MenuServiceProductStep) => {
            step.StepItems.filter((n) => n.QuantityToShow > 0).map((n) => {
               if (n.CanBeFractioned) {
                  for (let index = 0; index < n.QuantityToShow; index++) {
                     const subItem = this.getSubItem(n);
                     this.orderItem.SubItems.push(subItem);
                  }
               } else {
                  const subItem = this.getSubItem(n);
                  this.orderItem.SubItems.push(subItem);
               }
            });
         });
      }
   }

   private getSubItem(n: MenuServiceProductStepItem): OrderItem {
      return {
         ProductId: n.ProductId,
         ProductBarcode: n.MainBarcode,
         ProductName: n.ProductName,
         OriginalProductName: n.OriginalProductName,
         Quantity: n.Quantity,
         QuantityToShow: n.QuantityToShow,
         SellValue: toFloat(n.TotalValue / n.Quantity),
         OriginalSellValue: n.SellPrice,
         GrossValue: n.GrossValue,
         TotalValue: n.TotalValue,
         CanBeFractioned: n.CanBeFractioned,
         StepIndex: n.StepIndex,
         MarketingClassificationId: n.MarketingClassificationId,
         MarketingClassificationName: n.MarketingClassificationName,
         OriginalMarketingClassificationName: n.OriginalMarketingClassificationName,
         Observation: n.Observation,
         ProductTypeName: n.ProductTypeName,
         ApplyServiceCharge: n.ApplyServiceCharge,
         IsSplitOnSale: n.IsSplitOnSale,
         Printers: n.Printers,
         SubItems: [],
         CompleteObservation: '',
         CompositionMembers: [],
         PackageMembers: [],
         ServesHowManyPeople: n.ServesHowManyPeople,
      };
   }

   handleImageZoom(isOpen: boolean) {
      const element = document.getElementsByClassName('img-product-zoom')[0];

      if (isOpen) {
         element.classList.add('openZoom');
      } else {
         element.classList.remove('openZoom');
      }
   }

   openModalProductQuantity(orderItem: OrderItem) {
      this.productQuantityComponent.open(orderItem);
   }
}
