export class MenuServiceParameterizationResponse {
   public CompanyId: number;
   public CompanyName: string;
   public CompanyLogoUrl: string;
   public IsEnabled: boolean;
   public CanReceiveOrders: boolean;
   public CanCustomerCloseTable: boolean;
   public FriendlyUrl: string;
   public PrimaryColor: string;
   public SecondaryColor: string;
   public TextColor: string;
   public CanWaiterOpenOrderCheckOnCharge: boolean;
   public WeekBusinessHours: WeekBusinessHours[];
   public ServiceCharges: number[];

   public StoreIsOpen: boolean;

   constructor() {
      this.StoreIsOpen = false;
   }
}

export class WeekBusinessHours {
   public WeekdayName: string;
   public StartTime: string;
   public EndTime: string;
}

export class OrderCheckCustomerInfo {
   public Name: string;
   public Document: string;
   public Credit: number;
   public CardId: string;

   public updateCredit(credit: number) {
      this.Credit = credit;
   }
}

export class CollaboratorLoginRequest {
   public CompanyId: number;
   public Login: string;
   public Password: string;
}

export class CollaboratorLoginResponse {
   public Id: string;
   public CompanyId: number;
   public Login: string;
}

export class OrderCheckInfoResponse {
   public OrderCheckCode: number;
   public IsGroupCheck: boolean;
}

export class MenuServiceCharge {
   public Value: number;
   public CalculatedValue: number;
   public CalculatedTotalValue: number;
}

export class MenuServiceProduct {
   public Id: string;
   public Name: string;
   public OriginalName: string;
   public AdditionalInfo: string;
   public OriginalAdditionalInfo: string;
   public MainBarcode: string;
   public ProductTypeName: string;
   public ProductUnitAcronym: string;
   public ProductUnitCanBeFractioned: boolean;
   public ProductIsCompositionMember: boolean;
   public HideCompositionMember: boolean;
   public IsProductsAgeRestricted: boolean;
   public MarketingClassificationId: string;
   public MarketingClassificationName: string;
   public OriginalMarketingClassificationName: string;
   public ProductNotes: MenuServiceProductNote[];
   public CategoryName: string;
   public OriginalCategoryName: string;
   public CostValue?: number;
   public SellValue: number;
   public ImageUrl: string;
   public SmallImageUrl: string;
   public Quantity: number;
   public MaxQuantityOnSale: number;
   public TotalValue: number;
   public GrossValue: number;
   public Observation: string;
   public ApplyServiceCharge: boolean;
   public IsSplitOnSale: boolean;
   public ProductStepMinValue: number;
   public ProductStepMaxValue: number;
   public ProductSteps: MenuServiceProductStep[];
   public CompositionMembers: MenuServiceProduct[];
   public PackageMembers: MenuServiceProduct[];
   public Printers: MenuServiceOrderItemPrinter[];
   public GetWeight: boolean;
   public ServesHowManyPeople?: number;
   public PackageMemberQuantity?: number;

   constructor() {
      this.Quantity = 0;
      this.GrossValue = 0;
      this.TotalValue = 1;
   }
}

export class MenuServiceProductStep {
   public Id: string;
   public Position: number;
   public Name: string;
   public OriginalName: string;
   public Quantity: number;
   public MinQuantity: number;
   public MaxQuantity: number;
   public MinValue: number;
   public MaxValue: number;
   public CanRepeatSameItem: boolean;
   public IsFractionItems: boolean;
   public ValueCalculationType: number;
   public StepItems: MenuServiceProductStepItem[];
   public ProductStepSelectedItemsCount: number;
   public ProductSelectedIndex: string;

   constructor() {
      this.ProductStepSelectedItemsCount = 1;
   }
}

export class MenuServiceProductStepItem {
   public Id: string;
   public ProductId: string;
   public ProductName: string;
   public OriginalProductName: string;
   public AdditionalInfo: string;
   public OriginalAdditionalInfo: string;
   public MainBarcode: string;
   public Quantity: number;
   public QuantityToShow?: number;
   public SellPrice: number;
   public GrossValue: number;
   public TotalValue?: number;
   public DiscountValue: number;
   public AdditionValue: number;
   public ImageUrl: string;
   public SmallImageUrl: string;
   public CanBeFractioned?: boolean;
   public MarketingClassificationId: string;
   public MarketingClassificationName: string;
   public OriginalMarketingClassificationName: string;
   public Observation: string;
   public ProductTypeName: string;
   public StepIndex?: number;
   public ApplyServiceCharge: boolean;
   public IsSplitOnSale: boolean;
   public Printers: MenuServiceOrderItemPrinter[];
   public ServesHowManyPeople?: number;

   constructor() {
      this.Quantity = 0;
      this.TotalValue = 0;
      this.AdditionValue = 0;
      this.DiscountValue = 0;
   }
}

export class MenuServiceOrderItemPrinter {
   public Id: string;
   public PrinterId: string;
   public PrinterName: string;
   public PrinterTypeId?: string;
   public PrinterTypeName: string;
   public PrinterModelId?: string;
   public PrinterModelName?: string;
   public IpAddress: string;
   public Port?: number;
   public PortSpeed?: number;
   public InitCommand: string;
   public WindowsPrinterName: string;
   public MonitorCode?: number;
   public PrinterModelIsPanel: boolean;
   public OrderOriginId?: string;
   public OrderOriginName: string;
}

export class MenuServiceProductNote {
   public Name: string;
   public IsRequired: boolean;
   public SelectedOption: string;
}

export class GroupedMenuServiceProduct {
   public CategoryName: string;
   public Products: MenuServiceProduct[];
}
